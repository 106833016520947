/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="pb--50 pt--60" name={"uvod"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":900}}>
              
              <Title className="title-box fs--72" content={"Služby našeho autoservisu"}>
              </Title>

              <Text className="text-box fs--18" content={""}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"sluzby"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Výměna pneumatik"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Přezutí a vyvážení pneumatik Oprava defektu."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Od 500 Kč&nbsp;"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--60" content={"Plnění klimatizace&nbsp;"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Provádíme čištění, plnění , servis a dezinfekci autoklimatizací s chladivem R134 a.&nbsp; Umíme také najít a opravit netěsnost systému klimatizace."}>
              </Text>

              <Subtitle className="subtitle-box" content={"450Kč + 3 kč chybějící chladivo"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Nastavení geometrie"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Měření a seřízení geometrie kol.&nbsp; Pokud jste na vozidle měnily nějaké části náprav nebo se vám nerovnoměrně sjíždějí pneumatiky je nezbytné seřídit sbíhavost a celkovou geometri."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Od 790Kč / osobní vozidlo&nbsp;<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--60" content={"Antikorozní nástrik podvozku a dutin"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"&nbsp;Již více než 10 let se zabýváme vysoce kvalitní antikorozní ochranou podvozku vozidel osobních i užitkových. Odstraníme a zamezíme korozi podvozku."}>
              </Text>

              <Subtitle className="subtitle-box" content={"Od 4500Kč - 15 000 Kč&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Čištění DPF filtrů&nbsp;"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"<span style=\"color: var(--color-supplementary);\">Provádíme strojní čištění a regeneraci filtru pevných čátic DPF.<br>Svítí vám kontrolka filtru a vozidlo jede v nouzovém režimu&nbsp; a nemá tah jako předtím ? Pak je nejspíše ucpaný a maximálně zanesený filtr pevných části a je nutná jeho demontáž a strojní regenerace.&nbsp;<br><br><br></span><br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Čištění DPF filtrů&nbsp; od 2999 Kč&nbsp;<br>Demontáž - montáž&nbsp; a diagnostika&nbsp; <br>1800 Kč /osobní vozidla<br>"}>
              </Subtitle>

              <Subtitle className="subtitle-box mt--60" content={"Výkup vozidel"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":330}} content={"Pokud jste se rozhodli vaše vozidlo již dále neprovozovat nebo je poškozené a nechcete již do něj investovat další finance rádi ho od Vás vykoupíme.&nbsp;<br>Výkup vozidel a výkup autovraků v jakémkoliv stavu. Přebíráme plnou zodpovědnost za technický stav vozidla.&nbsp; Veškeré papírování a převod vozidla vyřešíme za Vás do 3 pracovních dnu nikam nemusíte. Nabízíme velice zajímavé výkupní ceny za Vaše vozidlo i autovrak.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Od 3000 kč&nbsp; do 150 000 kč&nbsp;"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-1rkryxn css-42e4bw pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
      
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" url={"https://autoservisblaza.cz/kontaktni-formular/"} href={"https://autoservisblaza.cz/kontaktni-formular/"} content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Autoservis Bláža Ostrava - Radvanice&nbsp;</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Pro Vaše auto uděláme vše, co budeme moct.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: rgb(255, 255, 255);\">Jsme Malý a nezávislý autoservis&nbsp; osobních i užitkových vozidel.&nbsp;<br></span>Měření a seřízení geometrie kol&nbsp;<br>Servis čištění a plnění klimatizací&nbsp;<br>Rychloservis<br>Pneuservis<br>Nástřiky podvozku a dutin&nbsp;<br>Strojní regenerace filtru pevných částic DPF<br>a jiné.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: white;\"><a href=\"+420777321587\">+420 777 321 587</a><br>Lihovarská&nbsp; 1378/11,&nbsp; Ostrava - Radvanice&nbsp;<br>Areál Bánských strojíren ( BASTRO )&nbsp;<br></span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">IČ: 63471710<br><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}